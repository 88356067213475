import React from 'react';

import { Box, Grid, Typography, Link as MuiLink } from '@mui/material';

import { styled } from '@mui/material/styles';

import Link from './Link';
import MBLogo from './MBLogo';


const LinkContainer = styled(Box)(({ theme }) => ({
  color: '#fff',
  fontSize: '1rem',
  lineHeight: 1.75,
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}));


const BottomNav = () => {

  return (
    <Box textAlign={{ xs: 'center', md: 'left' }}>
      <Grid
        container
        display="flex"
        justifyContent="flex-start"
        sx={{
          bgcolor: 'primary.main',
          p: 4,
          py: 8
        }}
      >
        <Grid xs={12} md={2} lg={2} item>
          <Box mb={{ xs: 2, md: 0 }}>
            <Link href="/">
              <MBLogo />
            </Link>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{
            pt: { xs: 2, md: 0 },
            pb: { xs: 0, md: 2 },
            '& a': {
              textDecoration: 'none',
              width: 'fit-content',
              color: 'inherit',
            },
          }}
        >
          <Box display="flex" justifyContent="center" flexDirection={{ xs: 'column', sm: 'row' }}>
            <Box flex={{ xs: 1, md: 'unset' }} mx={{ xs: 0, sm: 2, lg: 6 }}>
              <Typography color="secondary">FEATURES</Typography>

              <LinkContainer
                alignItems={{ xs: 'center', md: 'flex-start' }}
                mb={{ xs: 2, md: 0 }}
              >
                <Link sx={{ my: { xs: 1, sm: 0}}} href="/request-quotes/">Get Quotes</Link>
                <Link sx={{ my: { xs: 1, sm: 0}}} href="/best-mechanics/">Find a Mechanic</Link>
                <Link sx={{ my: { xs: 1, sm: 0}}} href="/cost-estimates/">Cost Estimates</Link>
                <Link sx={{ my: { xs: 1, sm: 0}}} href="/write-review/">Write Review</Link>
              </LinkContainer>
            </Box>

            <Box flex={{ xs: 1, md: 'unset' }} mx={{ xs: 0, sm: 2, lg: 6 }}>
              <Typography color="secondary">COMPANY</Typography>

              <LinkContainer
                alignItems={{ xs: 'center', md: 'flex-start' }}
                mb={{ xs: 2, md: 0 }}
              >
                <Link sx={{ my: { xs: 1, sm: 0}}} href="/end-user-terms-and-conditions/">User Terms</Link>
                <Link sx={{ my: { xs: 1, sm: 0}}} href="/garage-terms-and-conditions/">Mechanic Terms</Link>
              </LinkContainer>
            </Box>

            <Box flex={{ xs: 1, md: 'unset' }} mx={{ xs: 0, sm: 2, lg: 6 }}>
              <Typography color="secondary">MECHANICS</Typography>

              <LinkContainer
                alignItems={{ xs: 'center', md: 'flex-start' }}
                mb={{ xs: 2, sm: 0 }}
              >
                <Link sx={{ my: { xs: 1, sm: 0}}} href="/for-mechanics/">Register your Business</Link>
              </LinkContainer>
            </Box>
          </Box>
        </Grid>

        <Grid
          xs={12}
          md={2}
          lg={2}
          item
          sx={{
            pt: { xs: 2, md: 0 },
            pb: { xs: 0, md: 2 },
            '& a': {
              textDecoration: 'none',
              width: 'fit-content',
              color: 'inherit',
            },
          }}
        >
          <Typography color="secondary">CONNECT WITH US</Typography>

          <LinkContainer alignItems={{ xs: 'center', md: 'flex-start' }}>
            <Link sx={{ my: { xs: 1, sm: 0}}}href="/contact-us">Contact Us</Link>
            <MuiLink
              sx={{ my: { xs: 1, sm: 0}}}
              href="https://www.facebook.com/mechanicbuddyZA"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Facebook
            </MuiLink>
            <MuiLink
              sx={{ my: { xs: 1, sm: 0}}}
              href="https://www.linkedin.com/company/mechanicbuddy"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              LinkedIn
            </MuiLink>
          </LinkContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight="500"
            color="white"
            textAlign="right"
            fontSize={11}
            sx={{
              mt: {
                xs: 2,
                md: -4
              }
            }}
          >©{new Date().getFullYear()} MechanicBuddy
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BottomNav;
