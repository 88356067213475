import React, { useState } from 'react';

import { Box, Button, MobileStepper } from '@mui/material';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import FeatureReview from './FeatureReview';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const RecentReviews = ({ reviews }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === reviews.length - 1) {
      setActiveStep(0);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(reviews.length - 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };
  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Box>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'grid',
          },
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 4,
        }}
      >
        {reviews && reviews.map(review => (
          <FeatureReview key={review.id} review={review} />
        ))}
      </Box>

      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          }
        }}
      >

        <AutoPlaySwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={5000}
          slideStyle={{ display: 'flex', justifyContent: 'center' }}
        >
          {reviews.map(review => (
            <FeatureReview key={review.id} review={review} />
          ))}
        </AutoPlaySwipeableViews>

        <MobileStepper
          variant="dots"
          steps={reviews.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} aria-label="Next Review">
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} aria-label="Previous Review">
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default RecentReviews;
