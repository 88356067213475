import {
  Box,
  Card,
  CardActionArea,
  Typography,
} from '@mui/material';

import Rating from '@mui/material/Rating';
import { NextLinkComposed } from '../components/Link';


const FeatureReview = ({review}) => {
  return (
    <Card variant="outlined" sx={{ flexGrow: 1, mx: 0.5, }}>
      <CardActionArea 
        sx={{
          p: {
            xs: 1,
            md: 2
          },
          position: 'relative',
          height: '100%'
        }}
        component={NextLinkComposed}
        to={{ pathname: `/mechanic/${review.url_name}` }}
      >
        <Typography color="primary.dark" sx={{ mb: 1 }}>
          {review.business}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1
          }}
        >
          <Rating name="read-only" value={review.rating} readOnly />
          <Box
            component="span"
            sx={{
              fontSize: 12,
              ml: 1
            }}
          >
            ~ {review.user}
          </Box>
        </Box>

        <Box
          mt={0.5}
          mb={2}
          fontSize="12px"
          fontWeight={300}
          fontStyle="italic"
          lineHeight="20px"
          height={72}
        >
          {review.body.length > 180 ? review.body.slice(0, 180) + "..." : review.body}
        </Box>
        
        <Typography
          variant="body2"
          fontSize={12}
          sx={{
            position: 'absolute',
            bottom: 8,
            right: 8
          }}
        >{review.created}</Typography>
      </CardActionArea>
    </Card>
  )
}

export default FeatureReview;
